.settings{
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
   
    .css-i7rae6-MuiList-root {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        padding-top: 0;
        padding-bottom: 8px;
        width: 100%;
        max-width: 600px!important;
        background-color: #fff;

        .css-uob957-MuiListSubheader-root{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}