.itemNoti{
    .MuiButtonBase-root{
        height: 100px;
        width: 410px;
    }
    .image{
        .imageUrl{
            width: 100px!important;
            height: 60px!important;
        }
    }
    .name{
        position: absolute;
        top: 0;
        left: 130px;
    }
    .info{
        white-space: break-spaces;
        width: 213px;
        padding: 15px;
        font-size: 12px;
    }
    .status{
        .iconN {
            //font-size: 50px!important;
            color: gray;
            padding: 5px;
            border-radius: 50%;
            margin-right: 10px;
            //padding-left: 200px;
            cursor: pointer;
            &:hover {
              //font-size: 51px!important;
              color: red;
            }
          }
      
          .iconNR{
            //font-size: 50px!important;
            color: red;
            padding: 5px;
            border-radius: 50%;
            margin-right: 10px;
            //padding-left: 200px;
            cursor: pointer;
      
            &:hover {
              //font-size: 51px!important;
              color: gray;
            }
          }
    }

    .date{
        position: absolute;
        top: 76px;
        left: 132px;
    }
    .hr{
        color: gray;
        display: block;
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
        margin-inline-start: auto;
        margin-inline-end: auto;
        unicode-bidi: isolate;
        overflow: hidden;
        border-style: inset;
        border-width: 1px;
    }
}