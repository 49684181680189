.footer{
    color: var(--secondary-text-color);
    background-color: var(--main-bg-color);
    //width: 100%;
    min-height: 150px;
    padding: 0 3%;
    display: flex;
    flex-direction: column;

    .social{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 37px;
        padding: 22px;
    }

    .icons {
        font-size: 1.9rem !important;
        color:#fff;
        margin:0px 12px;
        cursor: pointer;
    }
    
    .footer_member_links {
        list-style: none;
        white-space: nowrap;
        color: white;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0;
        align-items: center;
        justify-content: center;
    }
    
    .footer_member_links li {
        font-size: 0.9rem;
        width: 20%;
        min-width: 190px;
        margin: 7px 5px;
        user-select: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .button {
        font-size: 0.9rem;
        text-align:center;
        width:150px;
    
        padding:0 ;
        margin-top:20px;
    
        color:#808080;
        border: solid #808080 1px;
        border-radius:1px;
      
    
        user-select: none;
        cursor: pointer;
    }
    .button:hover {
        color:#fff;
        border: solid #fff 1px;
    }
    
    .footer_copyright {
        margin-top: 10px;
        font-size: 0.8rem;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}