.newlistItem {
  width: 30%;
  height: 115px;
  background-color: var(--main-color);
  margin-right: 30px;
  overflow: hidden;
  cursor: pointer;
  color: white;
  position: relative;
  float: left;


  .imgA{
    position: absolute;
    .ani {
      width: 146px;
      height: 82px;
      object-fit: cover;
    }
    .cap{
      display: none;
    }
  }

  

  .name{
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    float: left;
    left: 155px;

    &:hover {
      text-decoration: underline;
    }
  }
  .desc {
    font-size: 12px;
    margin-bottom: 10px;
    height: 75px;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    position: absolute;
    float: left;
    top: 40px;
    left: 160px;
    color: gray;
  }

  video {
    width: 300px;
    height: 140px;
    object-fit: cover;
    position: static;
    top: 0;
    left: 0;
  }

  &:hover {
    width: 30%;
    height: 115px;
    //position: absolute;
    //top: -150px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
    box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
    border-radius: 5px;

    .ani {
      display: none;
    }

    .desc{
      display: none;
    }

    .imgC{
      position: absolute;
      .cap{
        width: 146px;
        height: 82px;
        object-fit: cover;
        display:flex;
      }
    }
    

    .itemInfo {
      display: flex;
      flex-direction: column;
      padding: 5px;

      .icons {
        position: absolute;
        float: left;
        top: 40px;
        left: 180px;

        .icon {
          border: 2px solid white;
          padding: 5px;
          border-radius: 50%;
          margin-right: 10px;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            //font-size: 51px!important;
            color: red;
          }
        }
        .iconR{
          color: red;
          border: 2px solid white;
          padding: 5px;
          border-radius: 50%;
          margin-right: 10px;
          font-size: 16px;
          cursor: pointer;
    
          &:hover {
            //font-size: 51px!important;
            color: white;
          }
        }


        .off{
          background-color: red;
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: center;

        }

        .online{
          background-color: green;
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .itemInfoTop {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 600;
        color: gray;

        .limit {
          border: 1px solid gray;
          padding: 1px 3px;
          margin: 0 10px;
        }
      }


      .genre {
        font-size: 14px;
        color: lightgray;
      }
    }
  }
}
