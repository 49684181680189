.profile{
    margin-top: 100px;

    .avatar{
        display: flex;
        align-items: center;
        justify-content: center;

        .iconavatar{
            font-size: 175px;
            color: white;
        }
    }

    .name{
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 25px;
    }

    .bodyProfile{
        margin-right: 25%;
        margin-left: 25%;
        margin-block: 40px;

        .css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-disabled {
            background-color: white!important;
        }
        
        .formx{
            display: flex;
            align-items: center;
            justify-content: center;
        
            form {
                width: 300px;
                height: 130px;
                padding: 30px;
                border-radius: 5px;
                //background-color: var(--main-color);
                display: flex;
                flex-direction: column;
                justify-content: space-around;
        
                input {
                height: 40px;
                border-radius: 5px;
                background-color: gray;
                color: white;
                padding-left: 10px;
        
                &::placeholder {
                    color: lightgray;
                }
                }
        
                .button {
                height: 40px;
                border-radius: 5px;
                background-color: red;
                color: white;
                border: none;
                font-size: 18px;
                font-weight: 500;
                cursor: pointer;
                }
        
                span {
                color: lightgray;
                b {
                    color: white;
                }
                }
            }
        }


        .formp{
            display: flex;
            align-items: center;
            justify-content: center;
        
            form {
                width: 300px;
                height: 130px;
                padding: 30px;
                border-radius: 5px;
                //background-color: var(--main-color);
                display: flex;
                flex-direction: column;
                justify-content: space-around;
        
                input {
                height: 40px;
                border-radius: 5px;
                background-color: gray;
                color: white;
                padding-left: 10px;
        
                &::placeholder {
                    color: lightgray;
                }
                }
        
                .button {
                height: 40px;
                border-radius: 5px;
                background-color: red;
                color: white;
                border: none;
                font-size: 18px;
                font-weight: 500;
                cursor: pointer;
                }
        
                span {
                color: lightgray;
                b {
                    color: white;
                }
                }
            }
        }
    }
}