.listItem {
  width: 300px;
  height: 470px;
  background-color: var(--main-color);
  margin-right: 30px;
  overflow: hidden;
  cursor: pointer;
  color: white;

  .new{
    position: absolute;
    margin: 15px;
    background-color: green;
    width: 125px;
    display: flex;
    justify-content: center;
  }

  img {
    width: 100%;
    height: 90%;
    object-fit: cover;
  }

  .name{
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  video {
    width: 300px;
    height: 140px;
    object-fit: cover;
    position: static;
    top: 0;
    left: 0;
  }

  &:hover {
    width: 300px;
    height: 470px;
    //position: absolute;
    top: -150px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
    box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
    border-radius: 5px;

    img {
      height: 140px;
    }

    .itemInfo {
      display: flex;
      flex-direction: column;
      padding: 5px;

      .icons {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        justify-content: center;
        position: relative;

        .icon {
          border: 2px solid white;
          padding: 5px;
          border-radius: 50%;
          margin-right: 10px;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            //font-size: 51px!important;
            color: red;
          }
        }
        .iconR{
          color: red;
          border: 2px solid white;
          padding: 5px;
          border-radius: 50%;
          margin-right: 10px;
          font-size: 16px;
          cursor: pointer;
    
          &:hover {
            //font-size: 51px!important;
            color: white;
          }
        }


        .off{
          background-color: red;
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: center;

        }

        .online{
          background-color: green;
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .itemInfoTop {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 600;
        color: gray;

        .limit {
          border: 1px solid gray;
          padding: 1px 3px;
          margin: 0 10px;
        }
      }

      .desc {
        font-size: 13px;
        margin-bottom: 10px;
        height: 75px;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .genre {
        font-size: 14px;
        color: lightgray;
      }
    }
  }
}
