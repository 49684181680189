.listItemCapitulos {
  width: 330px;
  height: 270px;
  background-color: var(--main-color);
  margin-right: 30px;
  overflow: hidden;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 69%;
    object-fit: cover;
  }
  
  .view{
    //width: 100%;
    //height: 100%;
    position: absolute;
    //left: 50px;
    //bottom: 0;
    //display: flex;
    //align-items: center;
    //justify-content: center;
  }
  
  .viewicon{
    color: red;
    font-size: 70px;
  }

  .namecap{
    position: absolute;
    margin-top: 230px;
    width: 90%;
    word-break: break-all;
  }

  video {
    width: 300px;
    height: 140px;
    object-fit: cover;
    position: static;
    top: 0;
    left: 0;
  }

  &:hover {
    width: 330px;
    height: 270px;
    //position: absolute;
    top: -150px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
    box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
    border-radius: 5px;

    img {
      height: 140px;
    }

    .itemInfo {
      display: flex;
      flex-direction: column;
      padding: 5px;
      width: 90%;

      .name{
        font-size: 14px;
        width: 90%;
        word-break: break-all;
      }

      .icons {
        display: flex;
        margin-bottom: 20px;
        margin-top: 15px;
        align-items: center;
        justify-content: center;

        .icon {
          border: 2px solid white;
          padding: 5px;
          border-radius: 50%;
          margin-right: 10px;
          font-size: 16px;
        }

        .iconR{
          color: red;
          border: 2px solid white;
          padding: 5px;
          border-radius: 50%;
          margin-right: 10px;
          font-size: 16px;
        }
      }
      .itemInfoTop {
        //display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 600;
        color: gray;
        word-break: break-all;

        .limit {
          border: 1px solid gray;
          padding: 1px 3px;
          margin: 0 10px;
        }
      }

      .desc {
        font-size: 13px;
        margin-bottom: 10px;
        word-break: break-all;
      }

      .genre {
        font-size: 14px;
        color: lightgray;
      }
    }
  }
}
