.featuredMobile {
  height: 100%;
  position: relative;
  

  .category {
    position: absolute;
    top: 80px;
    left: 50px;
    font-size: 30px;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;

    /*select {
      cursor: pointer;
      background-color: var(--main-color);
      border: 1px solid white;
      color: white;
      margin-left: 20px;
      padding: 5px;
    }*/
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    
  }

  .degrad{
    width: 100%;
    height: 100%;
    position: absolute;
    //left: 50px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(transparent, black);

  }

  .info {
    width: 100%;
    position: absolute;
    bottom: 100px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 300px;
    }

    .desc {
      margin: 20px 0px;
    }

    .buttons {
      display: flex;

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        margin-right: 10px;
        cursor: pointer;

        &.play {
          background-color: white;
          color: var(--main-color);
        }

        &.more {
          background-color: gray;
          color: white;
        }

        span {
          margin-left: 5px;
        }
      }
    }
  }
}
.modalMobile{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 600px;
  color: white;
  background-color: var(--main-color);
  border: 2px solid #000;

  .name{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
  }

  .desc{
    padding: 35px;
  }
  .inf{
    padding-left: 35px;
  }
}