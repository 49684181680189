.login {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("https://cloudbd.animeflixplus.com/v1/storage/buckets/653dbc7e603cf9649e39/files/664d739b9f03acbf2818/view?project=64a3a406b642bef5b2b9&mode=admin");
  background-size: cover;
  position: relative;

  .top {
    .wrapper {
      padding: 20px 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        height: 30px;
      }
    }
  }

  .container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;

    form {
      width: 300px;
      height: 300px;
      padding: 30px;
      border-radius: 5px;
      background-color: var(--main-color);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      
      .logo{
        display: flex;
        align-items: center;
        justify-content: center;

        .logoI{
          width: 65%;
        }

      }

      input {
        height: 40px;
        border-radius: 5px;
        background-color: gray;
        color: white;
        padding-left: 10px;

        &::placeholder {
          color: lightgray;
        }
      }

      .button {
        height: 40px;
        border-radius: 5px;
        background-color: red;
        color: white;
        border: none;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
      }


      .buttonR {
        height: 40px;
        border-radius: 5px;
        background-color: black;
        color: white;
        border: none;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
      }

      span {
        color: lightgray;
        b {
          color: white;
        }
      }
    }
  }
}
