.navbarMobile {
    width: 100%;
    color: white;
    font-size: 14px;
    position: fixed;
    top: 0;
    z-index: 999;
    background: linear-gradient(to top, transparent 0%, rgb(0, 0, 0, 0.3) 50%);
  
    &.scrolled{
      background-color: var(--main-color);
    }
  
    .container {
      padding: 0px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;
  
      .left {
        display: flex;
        align-items: center;
  
        img {
          height: 50px;
          margin-right: 40px;
        }
  
        span {
          margin-right: 20px;
          cursor: pointer;
        }
      }
  
      .right {
        display: flex;
        align-items: center;
  
        .icon {
          margin: 0px 15px;
          cursor: pointer;
        }
  
        img {
          width: 30px;
          height: 30px;
          border-radius: 5px;
          object-fit: cover;
          cursor: pointer;
        }
  
        .profile {
          .options {
            display: none;
            background-color: var(--main-color);
            border-radius: 5px;
          }
  
          span {
            padding: 10px;
            cursor: pointer;
          }
  
          &:hover {
            .options {
              display: flex;
              flex-direction: column;
              position: absolute;
            }
          }
        }
      }
    }
  }
  