.notification{

    .icon{
        cursor: pointer;
          &:hover {
            //font-size: 51px!important;
            color: red;
          }
    }

    .iconR{
        color: red;
        cursor: pointer;
          &:hover {
            //font-size: 51px!important;
            color: white;
          }
    }

    

    .opciones{
        position: absolute !important;
        //top: 84px !important;
        .MuiPaper-root{
            position: fixed;
            overflow-y: scroll;
            height: 85%;
        }

        .base-Popper-root{
          position: relative !important;
          left: auto !important;
          width: 424px;
          right: 396px;
      }

      .skeletonrect{
        position: absolute;
      }
    }
}