.loadin{
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.text{
    color: white;
    position: absolute;
}
.load{
    margin-block-start: 121px;
}