.searchBar {
    margin-left: auto;
}

.search_field {
    margin-left: auto;
    display: flex;
    transition: .3s;
    overflow: hidden;

    width: 2.5vw;
}
.search_field_expand {
    padding: 3px;
    margin-left: auto;
    display: flex;
    transition: .3s;
    overflow: hidden;

    border: gainsboro solid 1px;
    background-color: rgba(0, 0, 0, 0.568);
    width: 260px;
}
.icon {
    font-size: x-large !important;
    color: white;
    cursor: pointer;
    margin: auto 0;
}


.input_text {
    width: 100%;
    padding-right: 30px;
    margin-left: 5px;
    border: transparent;
    color: white;
    background-color: transparent;
}

.input_text:focus {
    outline: none;
}

.input_text::placeholder {
    color: rgba(255, 255, 255, 0.617);
    font-size: 1rem;
}